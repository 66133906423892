exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contratos-antecipacao-de-salario-tsx": () => import("./../../../src/pages/contratos/antecipacao-de-salario.tsx" /* webpackChunkName: "component---src-pages-contratos-antecipacao-de-salario-tsx" */),
  "component---src-pages-contratos-politica-de-privacidade-embedded-tsx": () => import("./../../../src/pages/contratos/politica-de-privacidade/embedded.tsx" /* webpackChunkName: "component---src-pages-contratos-politica-de-privacidade-embedded-tsx" */),
  "component---src-pages-contratos-politica-de-privacidade-index-tsx": () => import("./../../../src/pages/contratos/politica-de-privacidade/index.tsx" /* webpackChunkName: "component---src-pages-contratos-politica-de-privacidade-index-tsx" */),
  "component---src-pages-contratos-programa-de-indicacoes-embedded-tsx": () => import("./../../../src/pages/contratos/programa-de-indicacoes/embedded.tsx" /* webpackChunkName: "component---src-pages-contratos-programa-de-indicacoes-embedded-tsx" */),
  "component---src-pages-contratos-programa-de-indicacoes-index-tsx": () => import("./../../../src/pages/contratos/programa-de-indicacoes/index.tsx" /* webpackChunkName: "component---src-pages-contratos-programa-de-indicacoes-index-tsx" */),
  "component---src-pages-imprensa-tsx": () => import("./../../../src/pages/imprensa.tsx" /* webpackChunkName: "component---src-pages-imprensa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-seguranca-tsx": () => import("./../../../src/pages/seguranca.tsx" /* webpackChunkName: "component---src-pages-seguranca-tsx" */),
  "component---src-pages-sobre-a-facio-tsx": () => import("./../../../src/pages/sobre-a-facio.tsx" /* webpackChunkName: "component---src-pages-sobre-a-facio-tsx" */)
}

