/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/variables.css"
import "./src/styles/reset.css"
import "./src/styles/typeface.css"

import "./src/styles/elements.css"
import "./src/styles/wrapper.css"
import "./src/styles/intro.css"
import "./src/styles/btn.css"
import "./src/styles/tutorial.css"

import { saveHref } from './src/utils/acquisition'

export const onRouteUpdate = ({ location }) => {
  saveHref({ location })
}
