import { save, get } from './storage'

const MKT_HREF_KEY = 'mkt::ref';

export const saveHref = ({ location }) => {
  const { href } = location;
  if (href?.includes('utm_') || href?.includes('amigo')) {
    save({ key: MKT_HREF_KEY, value: btoa(href) })
  }
}

export const getHref = () => {
  const value = get({ key: MKT_HREF_KEY })
  if (value) {
    return atob(value);
  }
  return null
}
