import Cookies from 'universal-cookie'

const cookies = new Cookies()

const TWO_YEARS_IN_MILLISECONDS = 2 * 365 * 24 * 1000 * 60 * 60

export const getAllCookies = () => {
  return Object.keys(cookies.getAll()).map(key => `${key}=${cookies.get(key)}`)
    .join(';');
} 

const isLocalStorageAvailable = () => {
  if (typeof window === "undefined") {
    return false
  }
  if (!window.localStorage) {
    return false
  }
  const test = 'localStoragetestKey'
  try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
  } catch(e) {
      return false
  }
}

export const save = ({ key, value }) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, value)
    return
  }
  cookies.set(key, value, { path: '/', secure: true, expires: new Date(new Date().getTime() + TWO_YEARS_IN_MILLISECONDS) });
}

export const get = ({ key }) => {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(key)
  }
  return cookies.get(key)
}
